const defaultTheme = require('tailwindcss/defaultTheme');
const plugin = require('tailwindcss/plugin')

module.exports = {
  important: true,
  prefix: 'tw-',
  content: [
    './app/helpers/**/*.rb',
    './app/javascript/**/*.{js,jsx}',
    './app/views/**/*.{html,haml,erb}',
    './app/assets/images/**/*.svg'
  ],
  theme: {
    borderColor: ({ theme }) => ({
      ...theme('colors')
    }),
    borderRadius: {
      full: '9999px',
      none: '0px',
      xs: '1px',
      sm: '2px',
      md: '3px',
      lg: '4px',
      xl: '5px',
      '2xl': '6px',
      '4xl': '8px',
    },
    borderWidth: {
      0: '0px',
      1: '1px',
      2: '2px',
      3: '3px'
    },
    boxShadow: {
      sm: '0 2px 4px 0 rgb(10 24 46 / 0.12), 0 2px 12px 0 rgb(10 24 46 / 0.08)',
      md: '0 4px 32px -4px rgb(10 24 46 / 0.08), 0 4px 16px -4px rgb(10 24 46 / 0.04)',
      lg: '0 4px 72px -12px rgb(10 24 46 / 0.12), 0 4px 48px -12px rgb(10 24 46 / 0.08)',
      none: 'none'
    },
    colors: {
      transparent: 'transparent',
      white: '#FFFFFF',
      grey: {
        '025': '#FAFCFF',
        '050': '#F2F5FA',
        100: '#E4E9F2',
        200: '#D8E0ED',
        300: '#B6C2D6',
        400: '#919EB3',
        500: '#65748C',
        600: '#4A5A75',
        700: '#33445E',
        800: '#1A2B47',
        900: '#0A182E'
      },
      mint: {
        100: '#85EAB8',
        200: '#65DCA4',
        500: '#29A372'
      },
      green: {
        '025': '#E5FAED',
        '050': '#CCF5DC',
        400: '#2FBC7A',
        500: '#26A16C',
        600: '#208D62',
        800: '#156147'
      },
      blue: {
        '025': '#EBF4FF',
        '050': '#CEE4FE',
        100: '#A7CCFC',
        200: '#7DB2FB',
        300: '#5998F8',
        400: '#3D84F5',
        500: '#1D70F2',
        600: '#0F5ADB',
        800: '#0F3F9F',
        900: '#0D3281'
      },
      cyan: {
        '025': '#E4F9FB',
        '050': '#CDF2F9',
        300: '#5BC5E9',
        400: '#2EAEE0',
        500: '#2298CE',
        600: '#1B79AC',
        700: '#166699',
        800: '#115180'
      },
      red: {
        '025': '#FCEBE8',
        '050': '#F7D6D0',
        200: '#F0978F',
        300: '#ED7D73',
        400: '#E85E54',
        500: '#E2443C',
        600: '#CE2722',
        700: '#B62320',
        800: '#921C1C'
      },
      amber: {
        '025': '#FBF5E0',
        '050': '#FAECC2',
        100: '#F8DE96',
        200: '#F9D371',
        300: '#F9C04E',
        400: '#F8A92A',
        600: '#DA7510',
        800: '#823C0D'
      },
      lime: {
        '050': '#ECF5CB',
        700: '#548316'
      },
      orange: {
        200: '#F7B386',
        500: '#E56B29',
        600: '#DB5C1D'
      },
      mutedBlue: {
        '050': '#CCE5FC',
        300: '#679AE0',
        700: '#244596'
      },
      yellow: {
        300: '#F5DD58',
        400: '#F2D03B'
      },
    },
    columns: {
      auto: 'auto',
      1: '1',
      2: '2',
      3: '3',
      4: '4',
      5: '5',
      6: '6',
      7: '7',
      8: '8',
      9: '9',
      10: '10',
      11: '11',
      12: '12',
      '3xs': '256px',
      '2xs': '288px',
      xs: '320px',
      sm: '384px',
      md: '448px',
      lg: '512px',
      xl: '576px',
      '2xl': '672px',
      '3xl': '768px',
      '4xl': '896px',
      '5xl': '1024px',
      '6xl': '1152px',
      '7xl': '1280px'
    },
    fontFamily: {
      'inter': ['Inter', ...defaultTheme.fontFamily.sans],
      'mark-pro': ['Mark-Pro', ...defaultTheme.fontFamily.sans]
    },
    fontSize: {
      '3xl': ['32px', '40px'],
      '2xl': ['24px', '32px'],
      'xl': ['20px', '28px'],
      'l': ['16px', '24px'],
      'm': ['14px', '20px'],
      's': ['12px', '16px'],
      'xs': ['11px', '12px']
    },
    letterSpacing: {
      'tighter': '-0.02em',
      'tight': '-0.01em',
      'auto': '0',
      'wide': '0.01em',
      'wider': '0.02em'
    },
    maxWidth: ({ theme, breakpoints }) => ({
      none: 'none',
      0: '0px',
      xxs: '256px',
      xs: '320px',
      sm: '384px',
      md: '448px',
      lg: '512px',
      xl: '576px',
      '2xl': '672px',
      '3xl': '768px',
      '4xl': '896px',
      '5xl': '1024px',
      '6xl': '1152px',
      '7xl': '1280px',
      full: '100%',
      min: 'min-content',
      max: 'max-content',
      fit: 'fit-content',
      prose: '65ch',
      ...breakpoints(theme('screens'))
    }),
    ringColor: ({ theme }) => ({
      ...theme('colors')
    }),
    spacing: {
      px: '1px',
      0: '0px',
      0.5: '2px',
      1: '4px',
      1.5: '6px',
      2: '8px',
      2.5: '10px',
      3: '12px',
      3.5: '14px',
      4: '16px',
      5: '20px',
      6: '24px',
      7: '28px',
      7.5: '30px',
      8: '32px',
      9: '36px',
      10: '40px',
      11: '44px',
      12: '48px',
      14: '56px',
      16: '64px',
      20: '80px',
      24: '96px',
      28: '112px',
      32: '128px',
      36: '144px',
      39: '156px',
      40: '160px',
      44: '176px',
      48: '192px',
      52: '208px',
      56: '224px',
      60: '240px',
      62: '248px',
      64: '256px',
      72: '288px',
      80: '320px',
      96: '384px'
    },
    animation: {
      'spin-slow': 'spin 4s linear infinite',
    },
    backgroundOpacity: {
      64: '0.64'
    },
    aspectRatio: {
      '2/1': '2/1',
      'square': '1/1',
    },
    screens: {
      'max-w-900-h-400': { 'raw': '(max-width: 900px), (max-height: 400px)' },
      'max-h-300': { 'raw': '(max-height: 300px)' },
      'max-w-609': { 'max': '609px' },
    },
  },
  corePlugins: {
    preflight: false
  },
  plugins: [
    // source: https://github.com/tailwindlabs/tailwindcss/discussions/3378#discussioncomment-4177286
    plugin(({ matchUtilities, theme }) => {
      matchUtilities(
        {
          "animation-delay": (value) => {
            return { "animation-delay": value, };
          },
        },
        { values: theme("transitionDelay"), }
      );
    }),
  ],
}
